import { createDropdownToggle } from '@/logic/dom/dropdownToggle'

export const createMenuCallbacks = () => {
    // Create dropdown toggles
    const dropdownToggles = document.querySelectorAll('.aegean-main-navigation__dropdown-toggle');
    const dropdowns = document.querySelectorAll('.aegean-main-navigation__dropdown, .aegean-main-navigation__dropdown--mobile');

    if (dropdownToggles && dropdowns) {
    dropdownToggles.forEach(element => {
        let secondDropdownClass = element.classList[1] === 'wrapper-1' ? 'wrapper-2' : 'wrapper-1'
        createDropdownToggle(`${element.classList[1]}`, `for-${element.classList[1]}`, null, null, [`for-${secondDropdownClass}`])
    })
    }

    createDropdownToggle(
    'aegean-main-navigation__mobile-toggle',
    'aegean-main-navigation__mobile',
    )

    // Close all dropdowns when user clicks outside of them
    document.addEventListener('click', (e) => {
    if (!e.target.parentElement.classList.contains('aegean-main-navigation__dropdown-toggle')) {
        dropdowns.forEach(dropdown => dropdown.classList.remove('showing'))
    }
    });
}
